import React, { useState, useEffect } from "react";
import api from "../../../api/new-promotions";
import useStyles from "./styles";
import { Button, Grid, Tooltip } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";
import "./hero-banner.css";
import types from "../../../redux/types";
import { getRequestError } from "../../../utils/functions";
import { Delete, Edit } from "@material-ui/icons";
import AddHeroBanner from "./AddHeroBanner";
import DeleteHeroBanner from "./DeleteHeroBanner";
import Info from "../../../vectors/Info";
import Switch from "../../../components/Switch/Switch";

const HeroBanner = ({ user }) => {
  const classes = useStyles();
  const [openHeroBannerModal, setOpenHeroBannerModal] = useState(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [selectedPromotionId, setSelectedPromotionId] = useState({});
  const [selectedPromotion, setSelectedPromotion] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);

  const dispatch = useDispatch();
  const { ALERT_OPEN } = types;

  const handleOpenClose = (setter, value) => {
    return setter(value);
  };

  const fetchPromotions = _page => {
    setLoading(true);
    api
      .getList(`Bearer ${user.jwt}`, _page + 1, "banner")
      .then(res => {
        setFiltered(
          res?.data?.data?.map((item, index) => ({
            title: item?.title ?? "",
            description: item?.description ?? "",
            isActive: item?.isActive,
            url: !item.isMovie
              ? item?.url ?? ""
              : `https://fh-frontend-06-2024.vercel.app/movie/${item?.rosetta_movie_id}`,
            // position: item.position === "footer" ? "Footer" : "Banner",
            type: item.isMovie ? "Movie" : "Promotion",
            id: item?.id ?? index,
            youtubeTrailerURL: item?.youtube_trailer,
            // movieID: item?.movie_db_info_id,
            webMedia: item?.web_media,
            mobileMedia: item?.mobile_media,
            thumbnailImage: item?.thumbnail,
            rosettaId: item?.rosetta_movie_id ?? ""
          })) ?? []
        );
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    fetchPromotions(page);
  }, [page]);

  const handleAdd = payload => {
    return api
      .addPromotion(`Bearer ${user.jwt}`, payload)
      .then(res => {
        setOpenHeroBannerModal(false);
        fetchPromotions(0);
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const handleEdit = payload => {
    return api
      .editPromotion(`Bearer ${user.jwt}`, payload, selectedPromotion.data.id)
      .then(res => {
        setOpenHeroBannerModal(false);
        fetchPromotions(0);
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const handleDelete = id => {
    return api
      .deletePromotion(`Bearer ${user.jwt}`, id)
      .then(res => {
        setOpenDelete(false);
        fetchPromotions(0);
      })
      .catch(err => {
        dispatch({
          type: ALERT_OPEN,
          payload: {
            severity: "error",
            message: getRequestError(err)
          }
        });
        return err;
      });
  };

  const handleUpdatingIsActive = ({ id, status }) => {
    if (!isActiveLoading) {
      setIsActiveLoading(true);
      api
        .editPromotionStatus(`Bearer ${user.jwt}`, status, id)
        .then(res => {
          setIsActiveLoading(false);
          fetchPromotions(0);
        })
        .catch(err => {
          dispatch({
            type: ALERT_OPEN,
            payload: {
              severity: "error",
              message: getRequestError(err)
            }
          });
          return err;
        });
    }
  };

  const handleOpenDeleteModal = (e, id) => {
    e.preventDefault();
    setSelectedPromotionId(id);
    setOpenDelete(true);
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 300,
      renderCell: params => {
        return (
          <div className={classes.title}>
            {params?.row?.thumbnailImage ? (
              <img
                className={classes.image}
                alt={params.row.title}
                src={params.row.thumbnailImage}
                width={120}
                height={67}
              />
            ) : null}
            <span>{params.row.title}</span>
          </div>
        );
      }
    },
    {
      field: "description",
      headerName: "Description",
      width: 320,
      renderCell: params => {
        return (
          <div className={classes.ellipsisContainer}>
            <span className={classes.ellipsis}>
              {params.row.description ?? "-"}
            </span>
            <Tooltip
              classes={{
                tooltip: classes.tooltip
              }}
              title={params.row.description}
              arrow
            >
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex"
                }}
              >
                <Info />
              </div>
            </Tooltip>
          </div>
        );
      }
    },
    {
      field: "url",
      headerName: "Destination Url",
      width: 235,
      renderCell: params => {
        return (
          <div className={classes.ellipsisContainer}>
            <span className={classes.ellipsis}>{params.row.url ?? "-"}</span>
            <Tooltip
              classes={{
                tooltip: classes.tooltip
              }}
              title={params.row.url}
              arrow
            >
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex"
                }}
              >
                <Info />
              </div>
            </Tooltip>
          </div>
        );
      }
    },
    {
      field: "isActive",
      headerName: "Activate",
      width: 110,
      renderCell: params => {
        return (
          <Switch
            defaultChecked={params.row.isActive}
            checked={params.row.isActive}
            onChange={() => {
              params.row.isActive
                ? handleUpdatingIsActive({
                    id: params.row.id,
                    status: false
                  })
                : handleUpdatingIsActive({
                    id: params.row.id,
                    status: true
                  });
            }}
            disabled={isActiveLoading}
          />
        );
      }
    },
    {
      field: "id",
      headerName: " ",
      width: 100,
      renderCell: params => {
        return (
          <Edit
            color="primary"
            className={classes.editButton}
            onClick={() => {
              setSelectedPromotion({ data: params.row, type: "edit" });
              setOpenHeroBannerModal(true);
            }}
          />
        );
      }
    },
    {
      field: "movieID",
      headerName: " ",
      width: 100,
      renderCell: params => {
        return (
          <button onClick={e => handleOpenDeleteModal(e, params.row.id)}>
            <Delete color="secondary" />
          </button>
        );
      }
    }
  ];

  return (
    <div>
      <div className={classes.head}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item xs={6}></Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Grid justify="flex-end" container>
              {user.details.userType.toLowerCase() === "superadmin" ? (
                <Button
                  onClick={() => {
                    setSelectedPromotion({ data: null, type: "add" });
                    setOpenHeroBannerModal(true);
                  }}
                  className={classes.addBannerBtn}
                  variant="outlined"
                  color="primary"
                >
                  + ADD BANNER{" "}
                </Button>
              ) : null}
              <CSVLink data={filtered} filename={"hero-banners.csv"}>
                <Button variant="contained" color="primary">
                  <DownloadIcon style={{ marginRight: "10px" }} /> DOWNLOAD CSV
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <AddHeroBanner
        jwt={user.jwt}
        open={openHeroBannerModal}
        handleClose={() => handleOpenClose(setOpenHeroBannerModal, false)}
        add={handleAdd}
        edit={handleEdit}
        type={selectedPromotion.type}
        data={selectedPromotion.data}
      />
      <DeleteHeroBanner
        jwt={user.jwt}
        recordId={selectedPromotionId}
        open={openDelete}
        handleClose={() => handleOpenClose(setOpenDelete, false)}
        handleDelete={handleDelete}
      />
      {/* {loading ? (
        <div>
          <CircularLoad show={true} />
        </div>
      ) : ( */}
      <div style={{ height: "90vh", width: "100%", marginTop: "20px" }}>
        <DataGrid
          rows={filtered}
          columns={columns}
          pageSize={10}
          rowHeight={93}
          headerHeight={80}
          disableColumnMenu={true}
          autoHeight={true}
          page={page}
          rowCount={filtered.length}
          onPageChange={page => {
            setPage(page);
          }}
          paginationMode="server"
          loading={loading}
          className="hero-banners-table"
        />
      </div>
      {/* )} */}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user.user
});
export default connect(mapStateToProps)(HeroBanner);
